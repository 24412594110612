<template>
  <div>
    <!-- Conteúdo da sua aplicação Vue.js -->

    <v-btn v-show="showBackToTop" fab dark fixed bottom right @click="scrollToTop">
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showBackToTop: false
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Verifica se o scroll vertical é maior que 200 pixels para mostrar o botão,
      // caso contrário, esconde o botão
      this.showBackToTop = window.pageYOffset > 200;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>
